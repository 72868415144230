import React from "react";
import "./PastPosts.css";

function PastPosts(props) {
	return (
		<div className="col mb-4">
			<div className="pastposts-wrapper"  style={{ backgroundImage: `url(${props.imgurl})` }}>
				<div className="pastposts-text">
					<a href={props.url} >
						<h3>{props.heading}</h3>
					</a> 
					<p><b>Blog</b> | {props.date}</p>  
				</div>
			</div>
		</div>
	);
}

export default PastPosts;


{/* <div className="pastposts-text">
					<div className="pastposts-text">
					<a href={props.url} >
						<h3>{props.heading}</h3>
					</a> 
					              
				</div>
                    
				</div> */}