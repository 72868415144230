import React from "react";
import "./Timeline-copy.css";
import { Timeline1 } from "./timeline1";
import { Timeline2 } from "./timeline2";
import { Timeline3 } from "./timeline3";
import { Timeline4 } from "./timeline4";
// import { Timeline3 } from "./timeline-3";
// import { TimeLine4 } from "./timeline-4";



export const TimelineWhole2 = () => {

  return (
    <div className="timeline-bg">
      <div className="timeline">
        <div className="timeline1">
          <Timeline1/>
        </div>
        <div className="timeline2">
          <Timeline2/>
        </div>
        <div className="timeline3">
          <Timeline3/>
        </div>
        <div className="timeline4">
          <Timeline4/>
        </div>
      </div>
    </div>
  );
};