import React from "react";
import "./Blogs.css";
import blog1 from "./blog1.png";
import BImg1 from './blog2.png';
import BImg2 from './blog3.png';
import BImg3 from './blog4.png';
import BlogPost from "./BlogPost";

export const Blogs = () => {
	return (
		<section className="blogs-bg">
			<div className="row flex justify-center mt-5 mb-5">
				<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
					<div className="blogs-img">
						<img src={blog1} alt="" />
					</div>
				</div>
				<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
					<div className="text-area-blogs ">
						<h2 className="blogs-heading">Introducing Our Projects</h2>
						<p className="blogs-text mt-3">
							Software development outsourcing is just a tool to achieve
							business goals. But there is no way to get worthwhile results
							without cooperation and trust between a client company.
						</p>

						<button className="button-main mt-2">
							<div className="see-us-in-action">View More</div>
						</button>
					</div>
				</div>
			</div>
			<div className="row flex justify-center mt-5 mb-5">
                
                <BlogPost
                    imgurl={BImg1}
					heading="Digital Evolution: A Journey"
					date="Jan 15, 2024"
					url="/example-url"
				/>
				<BlogPost
					imgurl={BImg2}
                    heading="Consult Admitting: Power Acuteness"
					date="Feb 14, 2024"
					url="/example-url"
				/>
				<BlogPost
					imgurl={BImg3}
                    heading="Purple Teaming: A Guide"
					date="Dec 13, 2024"
					url="/example-url"
				/>
            </div>
		</section>
	);
};
