import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import BlogsPage from "./Pages/BlogsPage";
import Contact from "./Pages/Contact";
import AboutUs from "./Pages/AboutPage";
import Mobile from "./Pages/components/Services/Mobile/Mobile";
import QaService from "./Pages/components/Services/QaService";
import WebService from "./Pages/components/Services/WebService";
import SecurityService from "./Pages/components/Services/SecurityService";
import GraphicsService from "./Pages/components/Services/GraphicsService";
import ServicesPage from "./Pages/ServicesPage";
// import NavBar from "./Pages/components/Header/Navbar";
import Footer from "./Pages/components/Footer/Footer";
import { Testimonials1 } from "./Pages/components/Testimonials/Testimonials";

function App() {
	return (
		<div className="App">
			{/* <NavBar/> */}
			<BrowserRouter>
			<Routes>
				
				<Route path="/"  element={<Home/>} />
				<Route path="/test"  element={<Testimonials1/>} />
				<Route path="/footer"  element={<Footer/>} />
				<Route path="/blogs"  element={<BlogsPage/>} />
				<Route path="/contact"  element={<Contact/>} />
				<Route path="/about"  element={<AboutUs/>} />
				<Route path="/services"  element={<ServicesPage/>} />
				<Route path="/app"  element={<Mobile/>} />
				<Route path="/qa"  element={<QaService/>} />
				<Route path="/web"  element={<WebService/>} />
				<Route path="/security"  element={<SecurityService/>} />
				<Route path="/graphics"  element={<GraphicsService/>} />
				
			</Routes>
			</BrowserRouter>
			{/* <header className="App-header">
			</header> */}
			{/* <div className="Homepage-container">
				<Home/>
			</div>
			<div>
				<BlogsPage/>
			</div> */}
		</div>
	);
}

export default App;
