import React from "react";
import "./FF-Buttons.css";


export function NeoCircle(props){
	return (
		<div className="neo-circle-out">
			<img src={props.img} className="neoimg" alt="" />
			<div className="neotext">
                <div className="neoheading">{props.heading}</div>
                <div className="neopara">{props.description}</div>
            </div>
		</div>
	);
};
