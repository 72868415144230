import React from "react";
import './TestimonialCard.css'

const ReviewCard = (props) => {
	return (
		<div className="col">
			<div className="review-card">
				<p className="review-text">
					{props.reviewText}
				</p>
				<div className="review-desc">
					<div className="review-name">{props.reviewerName}</div>
					<div className="review-designation">
						{props.reviewerDesignation}
					</div>
				</div>
				<img className="review-img" src={props.imageUrl} alt="Reviewer" />
			</div>
		</div>
	);
};

export default ReviewCard;
