import React from 'react';
// Core modules imports are same as usual
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';
import './Partners.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import Partner1 from './assets/partner (1).png';
import Partner2 from './assets/partner (2).png';
import Partner3 from './assets/partner (3).png';
import Partner4 from './assets/partner (4).png';
import Partner5 from './assets/partner (5).png';
import Partner6 from './assets/partner (6).png';
import Partner7 from './assets/partner (7).png';
import Partner8 from './assets/partner (8).png';
import Partner9 from './assets/partner (9).png';
import Partner10 from './assets/partner (10).png';

function Partners() {
  return (
    <div className="container">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 5.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        autoplay={{ delay: 100, disableOnInteraction: false }} // Add autoplay option
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={Partner1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Partner2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Partner3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Partner4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Partner5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Partner6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Partner7} alt="slide_image" />
        </SwiperSlide>
                <SwiperSlide>
          <img src={Partner8} alt="slide_image" />
        </SwiperSlide>
                <SwiperSlide>
          <img src={Partner9} alt="slide_image" />
        </SwiperSlide>
                <SwiperSlide>
          <img src={Partner10} alt="slide_image" />
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default Partners;
