import React from "react";
import { useRef, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import "./timeline2.css";
import Tick from "./assets2/SVG_margin.svg";
import TImg2 from "./assets2/timeline2.svg";

export const Timeline2 = () => {
	const navigate = useNavigate()
	const imageRef = useRef(null);
	const borderRef = useRef(null);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (entry.target === imageRef.current) {
					const imageHeight = entry.target.clientHeight;
					borderRef.current.style.height = `${imageHeight + 3}px`;
				}
			}
		});

		if (imageRef.current) {
			resizeObserver.observe(imageRef.current);
		}

		return () => {
			if (imageRef.current) {
				resizeObserver.unobserve(imageRef.current);
			}
		};
	}, []);

	return (
		<div className="timelinepart2">
			<div className="timeline-text">
				<div className="heading-timeline">
					<div className="red-dot" />
					<span className="gap"></span>
					<div className="headingtext">Schedule A <br className="break"/> Consultation</div>
				</div>
				<div className="text">
					<div className="list">
						<div className="item">
							<img className="SVG-margin" alt="Svg margin" src={Tick} />
							<p className="p">Request a meeting with our team.</p>
						</div>
						<div className="item">
							<img className="SVG-margin" alt="Svg margin" src={Tick} />
							<p className="p">
								We&#39;ll discuss your objectives, answer your queries, and tailor solution.
							</p>
						</div>
					</div>
				</div>
				<div className="button-div">
					<button onClick={()=> navigate('/about')} className="button">
						<div className="buttontext">Schedule a consultation</div>
					</button>
				</div>
			</div>
			<div className="border-timeline" ref={borderRef}></div>
			<div className="timeline-img" ref={imageRef}>
				<img className="TImg2" alt="" src={TImg2} />
			</div>
		</div>
	);
};
