import React from 'react';
import './ServicesPage.css';
import Cards from './components/Solutions/Cards';
import Partners from './components/Partners/Partners';
import { Blogs } from './components/Blogs/Blogs';
import ServiceFeatures from './components/Services/ServiceFeatures';
import Footer from './components/Footer/Footer';
import NavBar from "./components/Header/Navbar";


function ServicesPage() {
  return (
    <div className='service-page'>
      <NavBar/>
        <div className="services-heading">
				  <h1 className="services-heading-text">We Offer a Wide Variety of <br /> IT Services</h1>
		    </div>
      <Cards/>
     
			<ServiceFeatures/>
			
				<Blogs />
			
				<Partners />
        
        <Footer/>
    </div>
  )
}

export default ServicesPage
