import React from 'react'
import './AboutCircles.css';
import Brain from './brain.svg';
import Bug from './bug-1.svg';
import Eyes from './eyes.svg';
import Cog from './cog.svg';
import Search from './search.svg';

function AboutCircles() {
  return (
    <div className="aboutcircles">
        <div className="container align-middle">
            <div className="row">
            <div className="col-12 ">
                <h1 className="stay-secured-head">Driven by Inspiration, <br/><span className="txt-type">Tailored for You!</span></h1>
                <p className="initiative-description">Motiventive’s groundbreaking solutions are designed to empower and delight our customers, who fuel our passion for innovation.</p>
    
            </div>
                </div>
        </div>
    <div className="comparison-container">
        <div className="container">
            <div className="feature-comparison">
                <div className="comparison-item traditional">
                    <div className="way-title">Innovating with Excellence</div>
                    
                    <div>
                        <div className="light-red-bg"><img src={Eyes} alt="Eye" />
                            <div>Proactive Security</div>
                        </div>
                    </div>
                    <div>
                        <div className="grey-bg"><img src={Bug} alt="Wasp" className="wasp-1" /> <img src={Bug} alt="Wasp" className="wasp-2" />
                            <div>Commitment to <b>inspiring and innovating</b> within the technology sphere is at the heart of Motiventive.</div>
                        </div>
                    </div>
                </div>
                
                <div className="comparison-item bs-way">
                    <div className="way-title">The <span>Motiventive</span> way</div>
                    <div className="before-title">Excellence Guaranteed</div>
                    <div className="white-circle-bg"><img src={Brain} alt="AI" />
                        <div><span>Client & Industry Specific</span> for seamless user experience & flexibility</div>
                    </div>
                    <div>
                        <div className="light-blue-circle"><img src={Search} alt="Search" />
                            <div>Customer Validations & Praise</div>
                        </div>
                        <div className="light-green-circle"><img src={Cog} alt="Automation" />
                            <div><b>Passionate & Experienced Team</b> with commitment to innovate</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="setup-bg">
            <div className="scores-container">
                <div className="score-wrapper"><i className="smiley-common smile-smiley"></i>
                    <div className="score-text">Client & Industry Specific</div>
                    <div className="pie-wrap">
                        <div className="semi-circle white"><span className="score">9.9</span></div>
                        <div className="semi-circle grey"><span className="out-of">10</span></div>
                    </div>
                </div>
                <div className="score-wrapper"><i className="smiley-common support"></i>
                    <div className="score-text">Passionate & Experienced Team</div>
                    <div className="pie-wrap">
                        <div className="semi-circle white"><span className="score">9.9</span></div>
                        <div className="semi-circle grey"><span className="out-of">10</span></div>
                    </div>
                </div>
                <div className="score-wrapper"><i className="smiley-common setup"></i>
                    <div className="score-text">Committed to Inspire & Innovate</div>
                    <div className="pie-wrap">
                        <div className="semi-circle white"><span className="score">10</span></div>
                        <div className="semi-circle grey"><span className="out-of">10</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default AboutCircles
