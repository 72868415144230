import React from "react";
import "./AboutPage.css"; // Import your CSS file for styling
import AboutSols from "./components/About/AboutSols";
import AboutCircles from "./components/About/AboutCircles";
import About from "./components/About/Aboout";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/Header/Navbar";


const AboutUs = () => {
	return (
		<div>
			<NavBar/>
			<About/>
			<AboutSols />
			<AboutCircles/>
			<Footer/>			
		</div>
	);
};

export default AboutUs;
