import { Collapse, Space } from "antd";
import "./Faq.css";
const { Panel } = Collapse;

function AppFAQ() {
	return (
		<div className="faqPage">
			<div className="faq-container">
				<h2>Frequently Asked Questions</h2>
				<Collapse 
                
                accordion 
				bordered={false}
                defaultActiveKey={["1"]}
                >
					<Panel
						className="faq-panel"
						header="What industries do we serve, and how do we accommodate businesses of varying sizes?"
						key="1"
					>
						<p>
							As a leading web app development consulting company, we cater to
							clients from a wide range of industries. From healthcare to
							finance, we have helped businesses of all sizes achieve their
							goals through our expertise in web app development.
						</p>
					</Panel>
					<Panel
						className="faq-panel"
						header="How do we manage project timelines and ensure timely delivery?"
						key="2"
					>
						<p>
							With a track record of completing projects on time, we work hard
							to meet your deadlines and ensure your web app is ready to go live
							when you want it to.
						</p>
					</Panel>
					<Panel
						className="faq-panel"
						header="How is communication managed during the development process?"
						key="3"
					>
						<p>
							We maintain open and transparent communication channels throughout
							development to keep you updated and involved at all stages.
						</p>
					</Panel>
				</Collapse>
			</div>
		</div>
	);
}

export default AppFAQ;
