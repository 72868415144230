import React from "react";
import { useRef, useEffect } from 'react';
import "./timeline3.css";
import Tick from './assets2/SVG_margin.svg';
import TImg3 from './assets2/timeline3.svg';
import {useNavigate} from 'react-router-dom';



export const Timeline3 = () => {
  const navigate = useNavigate()
  const imageRef = useRef(null);
  const borderRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === imageRef.current) {
          const imageHeight = entry.target.clientHeight;
          borderRef.current.style.height = `${imageHeight +3}px`;
        }
      }
    });

    if (imageRef.current) {
      resizeObserver.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        resizeObserver.unobserve(imageRef.current);
      }
    };
  }, []);
  
  return (
    <div className="timelinepart3">
        <div className="timeline-img" ref={imageRef}>
            <img className="TImg3" alt="" src={TImg3} />
        </div>
        <div className="border-timeline" ref={borderRef}></div>
        <div className="timeline-text">
            <div className="heading-timeline">
                <div className="red-dot" />
                <span className="gap"></span>
                <div className="headingtext">Personalized Strategy</div>
            </div>
            <div className="text">
                <div className="list">
                    <div className="item">
                        <img className="SVG-margin" alt="Svg margin" src={Tick} />
                        <p className="p">Receive a custom plan crafted just for you.</p>
                    </div>
                    <div className="item">
                        <img className="SVG-margin" alt="Svg margin" src={Tick} />
                        <p className="p">
                        We'll outline the steps, timelines, and resources needed to achieve goals.
                        </p>
                    </div>
                </div>
            </div>
            <div className="button-div">
                <button className="button" onClick={()=> navigate('/about')}>
                    <div className="buttontext">Personalized strategy</div>
                </button>
            </div>
        </div>
    </div>
  );
}