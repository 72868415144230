import React from "react";
import star from "./Star.svg";
import shield from "./Shield.svg";
import send from "./Send.svg";
import spiral from "./aboutimg.png";
import "./About.css";

const features = [
	{
		id: "feature-1",
		icon: star,
        title: "Innovation",
		content:
			"We ignite creativity to revolutionize and pioneer change in today's dynamic landscape.",
		
	},
	{
		id: "feature-2",
		icon: shield,
		title: "Experience",
		content:
			"Established thinking and transformation in 2020 led remarkable outcomes.",
	},
	{
		id: "feature-3",
		icon: send,
        title: "Planning",
		content:
			"We bring the right people together to challenge norms.",
		
	},
];

const FeatureCard = ({ icon, title, content, index }) => (
	<div className={`flex flex-row p-6 rounded-[20px] ${index !== features.length + 1 ? 'mb-6' : 'mb-0'} feature-card`} >
		<div className={"feature-icon w-[64px] h-[64px] rounded-full flex justify-center items-center bg-dimBlue"} >
			<img src={icon} alt="icon" className=" w-[50%] h-[50%] object-contain" />
		</div>
		<div className="flex-1 flex flex-col ml-3">
			<h4 className="feature-title font-poppins font-semibold text-[18px] leading-[23px] mb-1">
				{title}
			</h4>
			<p className="feature-p font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
				{content}
			</p>
		</div>
	</div>
);

const About = () => {
	return (
		<section className="about-bg">
			<div className="row flex justify-center mt-5 mb-5">
				<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
					<div className="text-area-about pl-7 lg:pl-10 xs:pl-4 xs:pr-4 mb-10">
						<h2 className="about-heading">
							We Design, Build &amp; Modernize Software Projects
						</h2>
						<p className="about-text mt-3">
							Motiventive is a specialised software development and consultancy
							company in Canada that specialises in dynamic development
							solutions that include mobile applications, web design, and much
							more. As the leading provider of IT solutions in Canada, our work
							speaks for itself. Motiventive turns projects into profits and
							visits into clients.
						</p>
              <button className="button-main mt-4">
                <div className="see-us-in-action">LEARN MORE</div>
              </button>
					</div>
				</div>
				<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pr-7">
					<img src={spiral} alt="" />
				</div>
			</div>
			<div className="flex-1 flex justify-center items-center md:ml-10 ml-0 md:mt-0 mt-10 relative flex-col">
				{features.map((feature, index) => (
					<FeatureCard key={feature.id} {...feature} index={index} />
				))}
			</div>
		</section>
	);
};

export default About;
