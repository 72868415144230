import React from "react";
import './AboutSols.css';
import Sol1 from './dast.png';
import Sol2 from './api.png';
import Sol3 from './shift-left.png';
import Sol4 from './compliance.svg';
import Sol5 from './onboarding.svg';

function AboutSols() {
	return (
        <div className="aboutsolutions-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-1 col-xl-2"></div>
						<div className="col-lg-10 col-xl-8">
                            <h5>- What We Offer -</h5>
							<h2 className="solution-title">
								Custom IT Solutions for Your Business
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-1"></div>
						<div className="col-xl-10">
							<div className="services-container">
								<div className="service-item">
									<img src={Sol1} alt="DAST" />
									<div className="service-name">Communication</div>
									<div className="service-description">
										At our company, we know the importance of communication. We
										are committed to keeping you informed about the progress of
										your project and addressing any inquiries you may have.
									</div>
								</div>
								<div className="service-item">
									<img src={Sol2} alt="API Security" />
									<div className="service-name">24/7 Support</div>
									<div className="service-description">
										We are available to assist you 24/7 throughout your software
										development journey. Whether it's dawn or dusk, our team is
										dedicated to providing support whenever you need it.
									</div>
								</div>
								<div className="service-item">
									<img src={Sol3} alt="Shift left" />
									<div className="service-name">Experience</div>
									<div className="service-description">
										Our experienced team has a pool of knowledge gained from
										successfully managing countless projects over the years.
										There's hardly a challenge we haven't encountered and
										conquered.
									</div>
								</div>
								<div className="service-item">
									<img src={Sol4} alt="Compliance" />
									<div className="service-name">Commitment</div>
									<div className="service-description">
										Compassion is at the core of our approach, and regardless of
										the reasons you choose our software services, our dedication
										is unwavering in delivering the best results for your
										project.
									</div>

                                    
								</div>
								<div className="service-item">
									<img src={Sol5} alt="Onboarding" />
									<div className="service-name">Quality Assurance</div>
									<div className="service-description">
										We prioritize quality assurance in every aspect. From
										rigorous testing to meticulous attention to detail, we
										strive to ensure excellence in every deliverable.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	);
}

export default AboutSols;
