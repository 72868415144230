import React from "react";
import "./BlogPost.css";
import Arrow from "./arrow.png";

function BlogPost(props) {
	return (
		<div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-4">
			<div className="blogcard-wrapper">
				<div className="blogcard-img">
					<img src={props.imgurl} alt="" />
				</div>
				<div className="blogcard-text">
                    <p><b>Blog</b> | {props.date}</p>
					<h3>{props.heading}</h3>
					<p className="blogspara">{props.para}</p>
					<a href={props.url} className="blogcard-arrow">
						<img src={Arrow} alt="" /> <p className="blog-more"> &nbsp; Read More</p>						
					</a>
				</div>
			</div>
		</div>
	);
}

export default BlogPost;
