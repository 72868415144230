import React from "react";
import "./card.css";
import Arrow from './arrow.png';


function Card(props) {
  return (
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-4">
      <div className="card-wrapper">
        <div className="card-area">
          <div className="card-text">
            <h3>{props.heading}</h3>
            <p>{props.description}</p>
            <a href={props.url} className="card-arrow"><img src={Arrow} alt="" /> GO</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;








// function Card({title, text, url }) {
//   return (
//     <div className="card text-center bg-dark animate__animated animate__fadeInUp">
//       <div className="card-body text-light">
//         <h4 className="card-title">{title}</h4>
//         <p className="card-text text-secondary">
//           {text
//             ? text
//             : "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magnam deserunt fuga accusantium excepturi quia, voluptates obcaecati nam in voluptas perferendis velit harum dignissimos quasi ex? Tempore repellat quo doloribus magnam."}
//         </p>
//         <a
//           href={url ? url : "#!"}
//           target="_blank"
//           className="blue-go"
//           rel="noreferrer"
//         >
//           Go to {title}
//         </a>
//       </div>
//     </div>
//   );
// }

// export default Card;
