import React from "react";
import { useRef, useEffect } from "react";
import "./timeline4.css";
import Tick from "./assets2/SVG_margin.svg";
import TImg4 from "./assets2/timeline4.svg";

export const Timeline4 = () => {
	const imageRef = useRef(null);
	const borderRef = useRef(null);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (entry.target === imageRef.current) {
					const imageHeight = entry.target.clientHeight;
					borderRef.current.style.height = `${imageHeight + 3}px`;
				}
			}
		});

		if (imageRef.current) {
			resizeObserver.observe(imageRef.current);
		}

		return () => {
			if (imageRef.current) {
				resizeObserver.unobserve(imageRef.current);
			}
		};
	}, []);

	return (
		<div className="timelinepart4">
			<div className="timeline-text">
				<div className="heading-timeline">
					<div className="red-dot" />
					<span className="gap"></span>
					<div className="headingtext">Bring Your Vision to Life</div>
				</div>
				<div className="text">
					<div className="list">
						<div className="item">
							<img className="SVG-margin" alt="Svg margin" src={Tick} />
							<p className="p">Once you're satisfied with the plan, we'll kickstart the process.</p>
						</div>
						<div className="item">
							<img className="SVG-margin" alt="Svg margin" src={Tick} />
							<p className="p">
								Our team will work to turn your vision into a reality that wil leave 
              you completely satisfied with our services.
							</p>
						</div>
					</div>
				</div>
				<div className="button-div">
					<button className="button">
						<div className="buttontext">Let's Bring Your Vision to Life</div>
					</button>
				</div>
			</div>
			<div className="border-timeline" ref={borderRef}></div>
			<div className="timeline-img" ref={imageRef}>
				<img className="TImg4" alt="" src={TImg4} />
			</div>
		</div>
	);
};
