import React from 'react'

function QaService() {
  return (
    <div>
      <div className="App">
       
      <iframe src="qaservice.html" style={{width:'100%', height: '100vh', overflow: 'hidden'}}></iframe>
    </div>
    </div>
  )
}

export default QaService
