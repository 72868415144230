import React from "react";
import "./TrendingPosts.css";
import Arrow from "./arrow.png";

function TrendingPosts(props) {
	return (
		<div className=" mb-4">
			<div className="trendingcard-wrapper">
				<div className="trendingcard-img">
					<img src={props.imgurl} alt="" />
				</div>
				<div className="trendingcard-text">
					<a href={props.url} >
						<h3>{props.heading}</h3>
					</a> 
					<p><b>Blog</b> | {props.date}</p>
					<p className="blogspara">{props.para}</p>
					
				</div>
			</div>
		</div>
	);
}

export default TrendingPosts;
