import React from 'react'

function Mobile() {
  return (
    <div>
      <div className="App">
       
      <iframe src="mobileservice.html" style={{width:'100%', height: '100vh'}}></iframe>
    </div>
    </div>
  )
}

export default Mobile
