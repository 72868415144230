import React from 'react'

function WebService() {
  return (
    <div>
      <div className="App">
       
      <iframe src="web.html" style={{width:'100%', height: '100vh'}}></iframe>
    </div>
    </div>
  )
}

export default WebService
