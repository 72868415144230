import React from "react";
import "./Home.css";
import { MainPage } from "./components/MainPage/MainPage";
import { TimelineWhole2 } from "./components/Timeline/Timeline-copy";
import { Solutions } from "./components/Solutions/Solutions";
import About from "./components/About/Aboout";
import Partners from "./components/Partners/Partners";
import { Blogs } from "./components/Blogs/Blogs";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/Header/Navbar";
import { Testimonials1 } from "./components/Testimonials/Testimonials";

function Home() {
	return (
		<div className="Home">
			<NavBar />
			<div className="MainPage-container">
				<MainPage />
			</div>
			<div className="Timeline-container">
				<TimelineWhole2 />
			</div>
			<div className="Solutions-container">
				<Solutions />
			</div>
			<div>
				<About />
			</div>

			<div>
				<Partners />
			</div>
			<div>
				<Testimonials1 />
			</div>
			<div>
				<Blogs />
			</div>

			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Home;
