import React from 'react'
import './GraphicsService.css'

function GraphicsService() {
  return (
    <div>
      <div className="service-frame">
              <iframe src="graphics.html" className="iframeStyle" frameborder="0"></iframe>

       </div>
    </div>
  )
}

export default GraphicsService
