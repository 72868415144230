import React from "react";
import { useRef, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import "./timeline1.css";
import Tick from './assets2/SVG_margin.svg';
import TImg1 from './assets2/timeline1.svg';
// import { useNavigation } from "../../../Routes/routes";

export const Timeline1 = () => {
    const navigate = useNavigate()
  //  const goToServices = () => {
  //   navigate('/services');
  // };

  // const { goToServices, goToContact } = useNavigation();

  const imageRef = useRef(null);
  const borderRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === imageRef.current) {
          const imageHeight = entry.target.clientHeight;
          borderRef.current.style.height = `${imageHeight}px`;
        }
      }
    });

    if (imageRef.current) {
      resizeObserver.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        resizeObserver.unobserve(imageRef.current);
      }
    };
  }, []);
  
  return (
    <div className="timelinepart1">
        <div className="timeline-img" ref={imageRef}>
            <img className="TImg1" alt="" src={TImg1} />
        </div>
        <div className="border-timeline" ref={borderRef}></div>
        <div className="timeline-text">
            <div className="heading-timeline">
                <div className="red-dot" />
                <span className="gap"></span>
                <div className="headingtext">Explore Our Services</div>
            </div>
            <div className="text">
                <div className="list">
                    <div className="item">
                        <img className="SVG-margin" alt="Svg margin" src={Tick} />
                        <p className="p">Discover the range of services we offer.</p>
                    </div>
                    <div className="item">
                        <img className="SVG-margin" alt="Svg margin" src={Tick} />
                        <p className="p">
                        Each service is designed to meet your specific needs and drive your success.
                        </p>
                    </div>
                </div>
            </div>
            <div className="button-div">
                <button onClick={()=> navigate('/about')} className="button">
                    <div className="buttontext">Explore our services</div>
                </button>
            </div>
        </div>
    </div>
  );
}