import React from 'react'

function SecurityService() {
  return (
    <div>
      <div className="App">
       
      <iframe src="cloud.html" style={{width:'100%', height: '100vh'}}></iframe>
    </div>
    </div>
  )
}

export default SecurityService
