import React from "react";
import Card from "./Card";
import "./card.css";

function Cards() {
	return (
		<div className="container">
			<div className="row">
				<Card
					heading="Web Development"
					description="Our web development services revolve around creating responsive and eye-catching websites that attract your audience."
					url="/example-url"
				/>
				<Card
					heading="Mobile Development"
					description="We aim to design strong, easily accessible mobile applications for iOS and Android platforms, ensuring your presence on every device."
					url="/example-url"
				/>
				<Card
					heading="Graphic Design"
					description="The importance of visual identity cannot be underestimated. Our graphic designers create elegant and integrated designs that appeal to your target audience."
					url="/example-url"
				/>
				<Card
					heading="QA & Testing"
					description="Our web development services revolve around creating responsive and eye-catching websites that attract your audience."
					url="/example-url"
				/>
				<Card
					heading="Cloud Security"
					description="Our mobile and cloud security solutions creates shield for your data and operations, assuring industry compliance and strengthening your digitalization."
					url="/example-url"
				/>
				<Card
					heading="Customer Support"
					description="We understand the significance of customer service. Our team remain close by to assist your clients and provide them with a favourable impression."
					url="/example-url"
				/>
			</div>
		</div>
	);
}

export default Cards;
