import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./Navbar.css"; // Import CSS file if needed

function NavBar() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleMenuToggle = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav>
			<div className="logo">Motiventive</div>
			<label htmlFor="btn" className="icon" onClick={handleMenuToggle}>
				<span className="fa-bars-icon">
					{isMenuOpen ? <FaTimes /> : <FaBars />}
				</span>
			</label>
			<input type="checkbox" id="btn" />
			<ul className={isMenuOpen ? "active" : ""}>
				<li>
					<Link to="/">Home</Link>
				</li>

				<li>
					<Link to="/about">About</Link>
				</li>

				<li>
					<label htmlFor="btn-2" className="show">
						{" "}
						Services <b>+</b>
					</label>
					<Link to="/services">
						Services <b>+</b>
					</Link>
					<input type="checkbox" id="btn-2" />
					<ul>
						<li>
							<Link to="/web">Web Development</Link>
						</li>
						<li>
							<Link to="/app">Mobile App Development</Link>
						</li>
						<li>
              <Link to="/qa">QA Testing</Link>
						</li>
						<li>
              <Link to="/graphics">Graphic Design</Link>
						</li>
						<li>
              <Link to="/security">Mobile and Cloud Security</Link>
							
						</li>
					</ul>
				</li>
				<li>
					<Link to="/blogs">Blogs</Link>
				</li>
				<li>
					<Link to="/contact">Contact</Link>
				</li>
			</ul>
		</nav>
	);
}

export default NavBar;
