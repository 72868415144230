import React from "react";
import "./Contact.css";
import bgImg from "./contact1.png";
import { useForm } from "react-hook-form";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gifImage from "./components/Contact/Modern Square Typographic Fashion Brand Logo.gif";
import AppFAQ from "./components/Contact/Faq";
import Partners from "./components/Partners/Partners";
import Bluebox from "./components/About/bluebox";
import Map from "./components/Contact/Map";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/Header/Navbar";

export default function Contact() {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	const onSubmit = (data) => console.log(data);

	// console.log(watch('username'));

	return (
		<div>
			<NavBar/>
			<section className="contact-page">
				<div className="blue-bg-contact">
					<div className="form-section">
						<div className="register">
							<div className="col-form">
								<div className="col-form-text">
									<h2>Get in touch</h2>
									<span>We are here for you! How can we help?</span>
								</div>

								<form
									id="form"
									className="flex flex-col"
									onSubmit={handleSubmit(onSubmit)}
								>
									<p className="input-text">Username</p>
									<input type="text" {...register("username")} />
									<p className="input-text">Email</p>
									<input
										type="text"
										{...register("email", {
											required: true,
											pattern: /^\S+@\S+$/i,
										})}
									/>
									{errors.email?.type === "required" && "Email is required"}
									{errors.email?.type === "pattern" && "Invalid email format"}
									<p className="input-text">Message</p>
									<input
										type="text"
										{...register("textMessage", {
											required: true,
											maxLength: 100,
										})}
									/>
									{errors.textMessage?.type === "required" &&
										"Text message is required"}
									{errors.textMessage?.type === "maxLength" &&
										"Max length exceeded (100 characters)"}
									<button className="btn">Submit</button>
								</form>
							</div>
							<div className="col-img">
								<div className="row">
									<img src={bgImg} alt="" />
								</div>
								<div className="row contact-details-area">
									<p className="contact-details">
										{" "}
										<FontAwesomeIcon className="i" icon={faLocationDot} />{" "}
										&nbsp;&nbsp; 20 Eglinton Ave E, Toronto, Ontario M4P 1A9, CA
									</p>
									<p className="contact-details">
										{" "}
										<FontAwesomeIcon className="i" icon={faEnvelope} />{" "}
										&nbsp;&nbsp; +92336 - 8289307
									</p>

									<p className="contact-details">
										{" "}
										<FontAwesomeIcon className="i" icon={faPhone} />{" "}
										&nbsp;&nbsp;Info@motiventive.co
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="faq-section">
						<AppFAQ />
					</div>
				</div>
				<div className="contact-partners mt-5">
					<h2 className="text-center font-bold text-4xl sm:text-left">
						We are helping Global Leaders with Digital Engineering
					</h2>
					<p className="text-center pt-2 sm:text-left">
						Become the next big story. Uptake the credibility of your business.
					</p>
					<Partners />
				</div>
				<Bluebox />
				<Map />
				<Footer/>
			</section>
		</div>
	);
}
