import React from "react";
import "./BlogsPage.css";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import BlogPost from "./components/Blogs/BlogPost";
import PastPosts from "./components/Blogs/PastPosts";

import BImg1 from "./components/Blogs/blog2.png";
import BImg7 from "./components/Blogs/blog7.png";
import BImg6 from "./components/Blogs/blog6.png";
import BImg5 from "./components/Blogs/blog5.png";
import BImg8 from "./components/Blogs/blog8.png";
import PImg1 from "./components/Blogs/pastpost1.png";
import PImg2 from "./components/Blogs/pastpost2.png";
import PImg3 from "./components/Blogs/pastpost3.png";
import TrendingPosts from "./components/Blogs/TrendingPosts";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/Header/Navbar";


function BlogsPage() {
	var settings = {
		className: "slider variable-width",
		dots: true,
		infinite: true,
		centerMode: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		variableWidth: true,
		// initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="BlogsPage">
			<NavBar/>
			<section className="trendingblogs">
                <div className="trend-text">
						<h1>
							Trending Posts
						</h1>
                        <p>Get the latest updates about technical advancements</p>
					</div>
				<div className="slider-container">
					<Slider {...settings}>
						<TrendingPosts
							imgurl={BImg8}
							heading="Digital Evolution: A Journey"
							date="Jan 15, 2024"
							url="/example-url"
							para="Give lady of they such they sure it. Me contained explained my education. Vulgar as hearts by garret. Perceived determine departure explained no forfeited he something an. Contrasted dissimilar get joy you instrument out reasonably. Again keeps at no meant stuff. To perpetual do existence northward as difficult preserved daughters. Continued at up to zealously necessary breakfast. Surrounded sir . . ."
						/>
						<TrendingPosts
							imgurl={BImg1}
							heading="Consult Admitting: Power Acuteness"
							date="Jan 15, 2024"
							url="/example-url"
                            para="Give lady of they such they sure it. Me contained explained my education. Vulgar as hearts by garret. Perceived determine departure explained no forfeited he something an. Contrasted dissimilar get joy you instrument out reasonably. Again keeps at no meant stuff. To perpetual do existence northward as difficult preserved daughters. Continued at up to zealously necessary breakfast. Surrounded sir . . ."

						/>
						<TrendingPosts
							imgurl={BImg5}
							heading="Purple Teaming: A Guide"
							date="Jan 15, 2024"
							url="/example-url"
                            para="Give lady of they such they sure it. Me contained explained my education. Vulgar as hearts by garret. Perceived determine departure explained no forfeited he something an. Contrasted dissimilar get joy you instrument out reasonably. Again keeps at no meant stuff. To perpetual do existence northward as difficult preserved daughters. Continued at up to zealously necessary breakfast. Surrounded sir . . ."

						/>
						<TrendingPosts
							imgurl={BImg7}
							heading="Digital Evolution: A Journey"
							date="Jan 15, 2024"
							url="/example-url"
                            para="Give lady of they such they sure it. Me contained explained my education. Vulgar as hearts by garret. Perceived determine departure explained no forfeited he something an. Contrasted dissimilar get joy you instrument out reasonably. Again keeps at no meant stuff. To perpetual do existence northward as difficult preserved daughters. Continued at up to zealously necessary breakfast. Surrounded sir . . ."

						/>
					</Slider>
				</div>
			</section>
			<section className="recentblogs">
				<div className="row">
					<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
						<h1>
							Recent Blog <br className="break" /> Posts
						</h1>
					</div>
					<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
						<p>
							Check out our latest blog posts for cutting-edge insights and
							updates on all things tech! Stay ahead of the curve and explore
							the future of technology with our curated content. Dive in now and
							unlock the knowledge that's shaping tomorrow's innovations!
						</p>
					</div>
				</div>
				<div className="row flex justify-center mt-5 mb-5">
					<BlogPost
						imgurl={BImg7}
						heading="Digital Evolution: A Journey"
						date="Jan 15, 2024"
						url="/example-url"
						para="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac ex nunc"
					/>
					<BlogPost
						imgurl={BImg5}
						heading="Consult Admitting: Power Acuteness"
						date="Feb 14, 2024"
						url="/example-url"
						para="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac ex nunc"
					/>
					<BlogPost
						imgurl={BImg6}
						heading="Purple Teaming: A Guide"
						date="Dec 13, 2024"
						url="/example-url"
						para="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac ex nunc"
					/>
				</div>
			</section>
			<section className="pastblogs">
				<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
					<h1>Past Events</h1>
				</div>
				<div className="row flex justify-center mt-5 mb-5">
					<div className="col-xs-12 col-sm-7 col-md-8 col-lg-8">
						<PastPosts
							imgurl={PImg1}
							url="/example-url"
							heading="Purple Teaming: A Guide Journey"
							date="Jan 15, 2024"
							className="pastpost1"
						/>
					</div>
					<div className="col-xs-12 col-sm-5 col-md-4 col-lg-4">
						<PastPosts
							imgurl={PImg2}
							url="/example-url"
							heading="Discovery incommode"
							date="Feb 14, 2024"
						/>
					</div>
				</div>
				<div className="row flex justify-center mt-5 mb-5">
					<PastPosts
						imgurl={BImg7}
						url="/example-url"
						heading="Digital Evolution: A Journey"
						date="Jan 15, 2024"
					/>
					<PastPosts
						imgurl={BImg5}
						url="/example-url"
						heading="Consult Admitting: Power Acuteness"
						date="Feb 14, 2024"
					/>
					<PastPosts
						imgurl={PImg3}
						url="/example-url"
						heading="Discovery MODE"
						date="Jan 15, 2024"
					/>
				</div>

			</section>
			<Footer/>
		</div>
	);
}

export default BlogsPage;
