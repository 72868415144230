import React from "react";
// import Slider from "react-slick";
import "./Testimonials.css";
// import { ReviewCard } from "./TestimonialCard";
import ReviewCard from "./TestimonialCard";
import Review1 from "./review1.jpg";

export const Testimonials1 = () => {

	return (
		<section className="testimonials1">
			<div className="row">
				<div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
					<div className="testimonials1-text">
						<div className="testimonials1-heading">Customer testimonials</div>
						<button className="review-button">
							<div className="review-buttontext">Read all testimonials</div>
						</button>
					</div>
				</div>
				<div className="col">
					
						<ReviewCard
						reviewText="From the onset, their overt professionalism and expertise were evident. They took the time to understand my requirements thoroughly, offering valuable insights."
						reviewerName="John Doe"
						reviewerDesignation="Product Owner, Trinity Software Solutions"
						imageUrl={Review1}
					/>
					

				</div>
			</div>
		
		</section>
	);
};
