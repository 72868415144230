import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import './Map.css';
import Canada from './canada.jpeg'
import Pakistan from './pakistan.jpeg'

function Map() {
  return (
    <div className='contactmap'>
    <div className="working-process ptb-100">
        <div className="container">
            <div className="default-section-title default-section-title-middle">
                <span>Where To Find Us</span>
                <h3>Our Locations</h3>
            </div>
            <div className="section-content">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                        <div className="working-process-card-3">
                            <img src={Canada} alt="" />
                            <h4>Canada</h4>
                            <p>20 Eglinton Ave E,Toronto, Ontario M4P 1A9, CA</p>
                            <br/>
                            <p><FontAwesomeIcon className="i" icon={faPhone} /> <b>+ 1 336 828 9307</b></p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                        <div className="working-process-card-3">
                            <img src={Pakistan} alt="" />
                            <h4>Pakistan</h4>
                            <p>A 42 - Block 4 Gulistan - e - Johar Karachi, Sindh</p>
                            <br/>
                            <p><FontAwesomeIcon className="i" icon={faPhone} /> <b>+ 9233 6828 9307</b></p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    <div className="contact-google-map">
        <iframe className="g-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.3311922676626!2d-123.12424168434235!3d49.27861154583857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673f0f5c80681%3A0x4edc8ed22cfb4697!2s250-997%20Seymour%20St%2C%20Vancouver%2C%20BC%20V6B%203M1%2C%20Canada!5e0!3m2!1sen!2sbd!4v1647341090199!5m2!1sen!2sbd"></iframe>

    </div>
    </div>
  )
}

export default Map
