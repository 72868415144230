import React from "react";
import './ServiceFeatures.css';

function ServiceFeatures() {
	return (
		<div>
			<div className="featurecontainer">
				<div className="ai-head">
					<span className="ai-gradient">
						An <span className="ai-color">AI</span> engine like none other
					</span>
				</div>
				<div className="features-section">
					<div className="feature-group">
						<div className="outer-wrap-1">
							<div className="feature-box magenta-box">
								<i className="icon-common api-icon"></i>
								<div className="feature-head">24/7 Customer support</div>
							</div>
							<div className="feature-box violet-box">
								<i className="icon-common tech-stack-icon"></i>
								<div className="feature-head">Flexible tech stacks</div>
							</div>
							<div className="feature-box light-green-box">
								<i className="icon-common recommendation-icon"></i>
								<div className="feature-head">Client & Industry Specific</div>
							</div>
						</div>
					</div>
					<div className="feature-group">
						<div className="outer-wrap-2">
							<div className="feature-box yellow-box">
								<i className="icon-common scenario-icon"></i>
								<div className="feature-head">Passionate & Experienced</div>
							</div>
							<div className="feature-box light-rose-box">
								<i className="icon-common vuln-icon"></i>
								<div className="feature-head">Implements Excellence</div>
							</div>
							<div className="feature-box light-blue-box">
								<i className="icon-common report-icon"></i>
								<div className="feature-head">Seamless User Experience</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServiceFeatures;
