import React from 'react'
import './blueboxstyle.css'
import BlueboxImg from './Modern_Square_Typographic_Fashion_Brand_Logo-removebg-preview.png'

function Bluebox() {
  return (
    <div className='bluebox'>
      <div className="container">
        <div className="enterprise-section">
            <div className="content-section">
                <div className="enterprise-title"><span>OUR SUCCESS STORIES</span>: Digital Transformation</div>
                <div className="enterprise-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum id
                    nemo cum repudiandae quisquam, modi ad, at iusto atque non adipisci natus pariatur corporis ab fuga
                    molestias debitis aliquid nisi.</div>
                <div className="demo-btn-wrap"><button
                        className="setup-btn-common enterprise-tn enterprise-schedule-btn">Schedule a demo</button> <a
                        href="/enterprise">Learn more <i className="ri-arrow-right-line"></i></a></div>
            </div><img src={BlueboxImg} alt="Pattern 1" className="bg-pattern" />
        </div>
    </div>
    </div>
  )
}

export default Bluebox



