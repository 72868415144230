import React from "react";
import "./Solutions.css";
import Cards from "./Cards";
import { NeoCircle } from "./FF-Buttons";
import Blue from "./SVG.svg";

export const Solutions = () => {
	return (
		<div className="solution-container">
			<div className="solution-heading">
				<h1 className="solution-heading-text">Our Solutions</h1>
			</div>
			<div className="cards-grid">
				<Cards />
			</div>
			<div className="neo-container">
				<div className="row">
					<div className="col col-xs-12 justify-content-center mb-4">
						<NeoCircle heading="55" description="Happy Clients" img={Blue} />
					</div>
					<div className="col col-xs-12 mb-4">
						<NeoCircle
							heading="50+"
							description="Finished Projects"
							img={Blue}
						/>
					</div>
					<div className="col col-xs-12 mb-4">
						<NeoCircle heading="40+" description="Skilled Experts" img={Blue} />
					</div>
					<div className="col col-xs-12 mb-4">
						<NeoCircle heading="100" description="Media Posts" img={Blue} />
					</div>
				</div>
			</div>
		</div>
	);
};
