import React from "react";
import "./MainPage.css";
import Mainimg from './mainImg.svg';
import { useNavigate } from 'react-router-dom';


export const MainPage = () => {

    const navigate = useNavigate();
  const goToAbout = () => {
    navigate('/about');
  };


  return (
    <div className="main-page">
      <div className="main-container">
        <section className="header"></section>
        <section className="main-section ">
          <div className="row">
            <div className="main-text col-lg-6 col-md-5 col-sm-5 col-xs-12">
              <h2 className="main-heading">
                Custom <span className="main-text-gradient">IT Solutions</span> For Your Business
              </h2>
              {/* <br /> */}
              <p className="description">
                We&#39;ve proudly  served the global tech community, earning the trust of over 50+ satisfied customers.
              </p>
             
              <button className="button-main" onClick={goToAbout}>
                <div className="see-us-in-action">SEE US IN ACTION</div>
              </button>
            </div>
            
            <div className="main-image col-lg-6 col-md-7 col-sm-7 col-xs-12">
              <img  alt="Main img" src={Mainimg} />
            </div>
          </div>
        </section>
        <section className="gradient">
          <div className="gradient-bg">
            <div className="gradient-text">WHY CHOOSE US</div>
            <div className="gradient-heading">How We Work</div> 
          </div>
        </section>
      </div>
    </div>
  );
};

