import React from 'react';
import './Footer.css';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from './logo.jpg';

function Footer() {
  return (
    <footer>
      <div className="footerbg">
        <div className="footercontainer">
          <div className="footer-row">
            <div className="footer-col" id="company">
              <div className="footermain">
                <a href="/"><img src={Logo} alt="" className="logo" /></a>
                <p>
                  Motiventive is your professional partner for online design,
                  development, security, and maintenance.
                </p>
                <div className='flex space-x-4 ml-10 pt-2 text-left'>
                       

                        <a className='text-white hover:text-pink-500 transform hover:scale-150 transition-all duration-150 ease-in-out 'href='https://github.com/'>
                            <FaLinkedin/>
                        </a>

                        <a className='text-white hover:text-pink-500 transform hover:scale-150 transition-all duration-150 ease-in-out 'href='https://twitter.com/'>
                            <FaTwitter/>
                        </a>

                        <a className='text-white hover:text-pink-500 transform hover:scale-150 transition-all duration-150 ease-in-out 'href='https://instagram.com/'>
                            <FaInstagram/>
                        </a>
                        <a className='text-white hover:text-pink-500 transform hover:scale-150 transition-all duration-150 ease-in-out 'href='https://facebook.com/'>
                            <FaFacebook/>
                        </a>
  
                    </div>   
                {/* <div className="social">
                  <a href="#"><FaFacebook className='i'/></a>
                  <a href="#"><FaInstagram className='i'/></a>
                  <a href="#"><FaTwitter className='i'/></a>
                  <a href="#"><FaLinkedin className='i'/></a>
                </div> */}
              </div>
            </div>

            <div className="footer-col" id="services">
              <h3>COMPANY</h3>
              <div className="links">
                <a href="/">Why Choose Us</a>
                <a href="/about">About Us</a>
                <a href="/services">Our Services</a>
                <a href="/blogs">Blogs</a>
                <a href="/contact">Contact Us</a>
              </div>
            </div>

            <div className="footer-col" id="contact">
              <h3>CONTACT</h3>
              <div className="contact-details">
                <FontAwesomeIcon className='i' icon={faLocationDot}/>
                <p>20 Eglinton Ave E, Toronto, Ontario, CA</p>
              </div>
              <div className="contact-details">
                <FontAwesomeIcon className='i' icon={faEnvelope}/>
                <p>Info@motiventive.co <br /> Sales@motiventive.co</p>
              </div>
              <div className="contact-details">
                <FontAwesomeIcon className='i' icon={faPhone}/>
                <p> +92336 - 8289307</p>
              </div>
            </div>
          </div>

          {/* <div className="footer-row">
            <div className="footer-form">
              <form action="">
                <input type="text" placeholder="Email here..." />
                <button><i className="fa fa-paper-plane"></i></button>
              </form>
            </div>
          </div> */}

          <div className="footer-row">
            <div className="copyright">
              <p>
                © Copyright 2023. Motiventive. All Rights Reserved. 2024
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
